<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="members"
      :sort-mapping="sortMapping"
      :title="'PC Members'"
      :total="membersTotal"
      :fetch-path="'pcMembers/filter'"
      :actions="true"
      @selectedItems="saveSelectedItems"
    >
      <template v-slot="{field}">
        <td class="text-end">
          <span
            :class="{ 'd-none': field.assigned != null && field.assigned === true}"
            class=""
          ><a
            class="btn btn-icon rounded-circle btn-flat-dark"
            @click="actions = {move: 1}, applyActions('Do you want to add this PC Member to the promotion call?', field)"
          ><i data-feather="plus" /></a></span>
          <span
            :class="{ 'd-none': field.assigned != null && field.assigned === false}"
            class=""
          ><a
            href="#"
            class="btn btn-icon rounded-circle btn-flat-danger"
            @click="actions = {move: 2}, applyActions('Do you want to remove this PC Member from the promotion call?', field)"
          ><i data-feather="x" /></a></span>
        </td>
      </template>
      <template #info-sheet-item>
        <div class="offcanvas-body offcanvas-body--view">
          <h4 class="mb-2">
            <strong class="text-success">{{ selectedItems.length }}</strong> selected PC Members
          </h4>
          <ul
            id="basic-list-group"
            class="list-group"
          >
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction1"
                      v-model="actions.move"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="1"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction1"
                    >Add to promotion</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction2"
                      v-model="actions.move"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="2"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction2"
                    >Remove from promotion</label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="offcanvas-footer mt-auto">
          <a
            href="#"
            title="apply actions"
            class="btn btn-primary mb-1 d-grid w-100"
            @click="applyActions('Perform the action on the selected PC Member/s?')"
          >Apply actions</a>
        </div>
      </template>
    </CompleteTable>
    <div
      v-else
      class="loading custom-loading"
    >
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import Vue from 'vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      loading: true,
      actions: {},
      selectedItems: [],
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'ERC',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Assigned call',
          checked: true,
          order: 5,
        },
        {
          name: 'Nº Evaluations',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas',
        ERC: 'erc.code',
        Institution: 'hosts_table',
        'Assigned call': 'assigned',
        'Nº Evaluations': 'evaluations',
      },
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      filters: 'convo/filters',
      fields: 'modals/tableSettingsFields',
      members: 'pcMembers/items',
      membersTotal: 'pcMembers/itemsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('pcMembers/filter', { pcmember_convo: this.convo.id })
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)

    await this.$store.dispatch('modals/fetchUserFields', 'pcmembers_convo_table')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'pcmembers_convo_table',
      })
    }
    this.loading = false
  },
  methods: {
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name && search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async applyActions(text, item) {
      if (this.selectedItems.length > 0 || item) {
        Vue.swal({
          title: text,
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(async result => {
          this.loading = true
          if (result.isConfirmed) {
            let itemIds = []
            if (item) {
              itemIds = [item.id]
            } else {
              itemIds = this.selectedItems.map(el => el.id)
            }

            await this.$store.dispatch('pcMembers/assignCall', { actions: this.actions, itemIds, convoId: this.convo.id })
            this.saveSelectedItems([])
            this.actions = {}
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
            this.$store.dispatch('pcMembers/filter', { pcmember_convo: this.convo.id })
            Vue.swal('Actions applied successfully!', '', 'success')
            this.$emit('reloadStats')
          }
          this.loading = false
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
  },
}
</script>
